:root {
  --blue: #64C9CF;
  --dark-orange: #DF711B;
  --orange: #FFB740;
  --sandy-orange: #FF7A00;
  --beige: #FDE49C;
  --off-white: #f3f0e2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: hsl(210, 36%, 96%);
  color: hsl(209, 61%, 16%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  letter-spacing:  0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: 'Koulen', cursive;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  padding: 5rem 1rem;
}
.menu-items-container {
  padding-top: 1.5rem;
}
.title {
  text-align: center;
  margin-bottom: 2rem;
}

.underline {
  width: 6rem;
  height: 0.25rem;
  background: var(--blue);
  margin-left: auto;
  margin-right: auto;
}

.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0.5rem 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
	color: var(--off-white);
  border-color: var(--blue);
  background-color: var(--blue);
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;
}

.active {
  color: var(--blue);
  background-color: var(--off-white);
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: center;
}

.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}

.photo {
  object-fit: cover;
  height: 200px;
  max-width: 100%;
  border: 0.25rem solid var(--beige);
  border-radius: 0.25rem;
  display: block;
}

.logo {
  object-fit: cover;
  height: auto;
  width: 200px;
  border-radius: 0.25rem;  
}

.item-info header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted var(--blue);
}
.item-info h4 {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-family: 'Radio Canada', sans-serif;
}
.price {
  color: var(--dark-orange);
}
.item-text {
  margin-bottom: 0;
  padding-top: 1rem;
}


@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo {
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}